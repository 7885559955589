// assets
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BookIcon from '@mui/icons-material/Book';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CollectionsIcon from '@mui/icons-material/Collections';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmailIcon from '@mui/icons-material/Email';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
const icons = {
    LibraryBooksIcon,
    BookIcon,
    AccessibilityNewIcon,
    ListIcon,
    SettingsIcon,
    CollectionsIcon,
    NewspaperIcon,
    HandshakeIcon,
    EmailIcon,
    EmojiPeopleIcon
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        {
            id: 'diplomasivakfi',
            title: 'Diplomasi Vakfı',
            caption: 'diplomasivakfi.org',
            type: 'group',
            children: [
                {
                    id: 'dv_contents',
                    title: 'Etkinlikler',
                    type: 'item',
                    url: '/diplomasivakfi/events',
                    icon: icons.ListIcon,
                },
                {
                    id: 'dv_publications',
                    title: 'Yayınlar',
                    type: 'item',
                    url: '/diplomasivakfi/publications',
                    icon: icons.BookIcon,
                },
                {
                    id: 'dv_analysis',
                    title: 'Analizler',
                    type: 'item',
                    url: '/diplomasivakfi/analysis',
                    icon: icons.LibraryBooksIcon,
                },
                {
                    id: 'dv_us',
                    title: 'Kadro',
                    type: 'item',
                    url: '/diplomasivakfi/us',
                    icon: icons.AccessibilityNewIcon,
                },
                {
                    id: 'dv_infographic',
                    title: 'İnfografik',
                    type: 'item',
                    url: '/diplomasivakfi/infographic',
                    icon: icons.CollectionsIcon,
                },
                {
                    id: 'dv_internship',
                    title: 'Staj Başvuruları',
                    type: 'item',
                    url: '/diplomasivakfi/internship',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_academy',
                    title: 'Akademi Başvuruları',
                    type: 'item',
                    url: '/diplomasivakfi/academy',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_academy2',
                    title: 'Akademi Başvuruları - 2',
                    type: 'item',
                    url: '/diplomasivakfi/academy-2',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_academy3',
                    title: 'Akademi Başvuruları - 3',
                    type: 'item',
                    url: '/diplomasivakfi/academy-3',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_academy4',
                    title: 'Akademi Başvuruları - 4',
                    type: 'item',
                    url: '/diplomasivakfi/academy-4',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_academy4',
                    title: 'Akademi Başvuruları - 5',
                    type: 'item',
                    url: '/diplomasivakfi/academy-5',
                    icon: icons.EmojiPeopleIcon,
                },
                {
                    id: 'dv_settings',
                    title: 'Ayarlar',
                    type: 'item',
                    url: '/diplomasivakfi/settings',
                    icon: icons.SettingsIcon,
                }
            ]
        },
        {
            id: 'earnportal',
            title: 'Earn Portal',
            caption: 'earn-portal.com',
            type: 'group',
            children: [
                {
                    id: 'ep_gallery',
                    title: 'Galeri',
                    type: 'item',
                    url: '/earnportal/gallery',
                    icon: icons.CollectionsIcon,
                },
                {
                    id: 'ep_news',
                    title: 'Haberler',
                    type: 'item',
                    url: '/earnportal/news',
                    icon: icons.NewspaperIcon,
                },
                {
                    id: 'ep_outputs',
                    title: 'Çıktılar',
                    type: 'item',
                    url: '/earnportal/outputs',
                    icon: icons.LibraryBooksIcon,
                },
                {
                    id: 'ep_partners',
                    title: 'Partnerler',
                    type: 'item',
                    url: '/earnportal/partners',
                    icon: icons.HandshakeIcon,
                },
                {
                    id: 'ep_projects',
                    title: 'Projeler',
                    type: 'item',
                    url: '/earnportal/projects',
                    icon: icons.ListIcon,
                },
                {
                    id: 'ep_messages',
                    title: 'Mesajlar',
                    type: 'item',
                    url: '/earnportal/messages',
                    icon: icons.EmailIcon,
                },
                {
                    id: 'ep_settings',
                    title: 'Ayarlar',
                    type: 'item',
                    url: '/earnportal/settings',
                    icon: icons.SettingsIcon,
                }
            ]
        },
        {
            id: 'turkeyinfo',
            title: 'TurkeyInfo',
            caption: 'turkeyinfo.org',
            type: 'group',
            children: [
                {
                    id: 'ti_contents',
                    title: 'İçerikler',
                    type: 'item',
                    url: '/turkeyinfo/contents',
                    icon: icons.ListIcon,
                }
            ]
        },
    ]
};

export default menuItems;
