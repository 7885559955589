import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const Events = Loadable(lazy(() => import('views/DiplomasiVakfi/events')));
const AddEvent = Loadable(lazy(() => import('views/DiplomasiVakfi/events/add')));

const Publications = Loadable(lazy(() => import('views/DiplomasiVakfi/publications')));
const AddPublication = Loadable(lazy(() => import('views/DiplomasiVakfi/publications/add')));

const Analysis = Loadable(lazy(() => import('views/DiplomasiVakfi/analysis')));
const AddAnalysis = Loadable(lazy(() => import('views/DiplomasiVakfi/analysis/add')));

const Us = Loadable(lazy(() => import('views/DiplomasiVakfi/us')));
const AddUs = Loadable(lazy(() => import('views/DiplomasiVakfi/us/add')));

const Infographic = Loadable(lazy(() => import('views/DiplomasiVakfi/infographic')));
const AddInfographic = Loadable(lazy(() => import('views/DiplomasiVakfi/infographic/add')));
const Settings = Loadable(lazy(() => import('views/DiplomasiVakfi/settings')));
const Internship = Loadable(lazy(() => import('views/DiplomasiVakfi/internship')));
const Academy = Loadable(lazy(() => import('views/DiplomasiVakfi/internship/academy')));
const AcademyTwo = Loadable(lazy(() => import('views/DiplomasiVakfi/internship/academy-two')));
const AcademyThree = Loadable(lazy(() => import('views/DiplomasiVakfi/internship/academy-three')));
const AcademyFour = Loadable(lazy(() => import('views/DiplomasiVakfi/internship/academy-four')));
const AcademyFive = Loadable(lazy(() => import('views/DiplomasiVakfi/internship/academy-five')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const DiplomasiVakfiRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/diplomasivakfi/events',
            element: <Events />,
        },
        {
            path: '/diplomasivakfi/events/add',
            element: <AddEvent />
        },
        {
            path: '/diplomasivakfi/publications',
            element: <Publications />
        },
        {
            path: '/diplomasivakfi/publications/add',
            element: <AddPublication />
        },
        {
            path: '/diplomasivakfi/analysis',
            element: <Analysis />
        },
        {
            path: '/diplomasivakfi/analysis/add',
            element: <AddAnalysis />
        },
        {
            path: '/diplomasivakfi/infographic',
            element: <Infographic />
        },
        {
            path: '/diplomasivakfi/infographic/add',
            element: <AddInfographic />
        },
        {
            path: '/diplomasivakfi/us',
            element: <Us />
        },
        {
            path: '/diplomasivakfi/us/add',
            element: <AddUs />
        },
        {
            path: '/diplomasivakfi/settings',
            element: <Settings />
        },
        {
            path: '/diplomasivakfi/internship',
            element: <Internship />
        },
        {
            path: '/diplomasivakfi/academy',
            element: <Academy />
        },
        {
            path: '/diplomasivakfi/academy-2',
            element: <AcademyTwo />
        },
        {
            path: '/diplomasivakfi/academy-3',
            element: <AcademyThree />
        },
        {
            path: '/diplomasivakfi/academy-4',
            element: <AcademyFour />
        },
        {
            path: '/diplomasivakfi/academy-5',
            element: <AcademyFive />
        },
    ]
};

export default DiplomasiVakfiRoutes;
